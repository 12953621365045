import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "技術を使うための技術も大切というお話",
  "date": "2020-10-23T09:19:24.000Z",
  "slug": "entry/2020/10/23/181924",
  "tags": ["medley"],
  "hero": "./2020_10_23.png",
  "heroAlt": "技術の技術"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`初めまして。CLINICS の開発を担当しているエンジニアの平山です。
`}<strong parentName="p">{`（同姓ですが CTO ではございません）`}</strong></p>
    <p>{`CLINICS の開発は「スモールチーム制」をとっておりまして、現在そのうちの１つをチームリードしています。`}</p>
    <p>{`前職は長らく SIer に勤めていました。去年の 12 月にメドレーに JOIN して、間も無く１年経とうとしている。。と思うと、あっという間だったなぁという印象です。`}</p>
    <p>{`さて、本日はメドレーで隔週開催している社内勉強会（TechLunch）において発表した内容についてご紹介させて頂ければと思います。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`「技術を使うための技術」というテーマとなりますが、プロダクト開発をしていく上では欠かせない素養と考えています。メドレーに所属しているエンジニアの１人として、どのように日々課題と向き合っているのか。当テーマを通してお伝えできればと思います。`}</p>
    <p>{`また、この考え方自体は「医療というテーマ」や「事業の背景（ベンチャー・ SIer）」を問わず必要とされる場面があるかもしれません。（自身も前職では様々な場面でお世話になりました...）`}</p>
    <p>{`即効性のあるものではありませんが、じわじわ効いてくる内容ではないかと思います。よろしければお付き合いください。`}</p>
    <h1>{`本題`}</h1>
    <p>{`「技術を使うための技術」`}</p>
    <p>{`みなさんはこの言葉から、何を思い浮かべるでしょうか。筆者が試しに Google で検索した際上位にヒットしたのは`}</p>
    <blockquote>
      <p parentName="blockquote">{`AI エンジニア
IoT`}</p>
    </blockquote>
    <p>{`といった結果でした。なるほど。少し雑に解釈すると「技術（アルゴリズム等）を使うための技術（機械学習、家電等）」といった感じなのでしょうか。（この結果を拾ってきたというのも、いい意味で Google すごいなって感じました）`}</p>
    <p><strong parentName="p">{`筆者が今回のテーマとして指しているのは、下記となります。`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`ロジカルシンキング
推論`}</p>
    </blockquote>
    <p>{`これらの `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`思考を整理する「手段」`}</code>{` とエンジニアの武器である `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`技術という「手段」`}</code>{` をかけ合わせることで、大きなテーマである「医療」に向き合っています。`}</p>
    <h1>{`手段を目的にしてはならない`}</h1>
    <p>{`先に挙げたとおり「思考の整理」も「技術」も `}<strong parentName="p">{`「手段」`}</strong>{` に過ぎません。これらを用いて、適切な一手を指していく為には「目的」に対する解像度を高く描く必要があります。`}</p>
    <p><strong parentName="p">{`筆者の発表から抜粋した「技術を使うための技術」の要素をイメージした図`}</strong></p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201023/20201023135338.png",
      "alt": "20201023135338.png"
    }}></img>
    <h2>{`整理力`}</h2>
    <p>{`目的を達成する為に必要な「情報」を取捨選択するための要素。`}</p>
    <p>{`SaaS ✖️ toB の世界においては「プロダクトが解決すべき課題か否かを業務の本質を踏まえて取捨選択する」と言い換えられるかもしれません。`}</p>
    <h2>{`業務知識`}</h2>
    <p>{`目的の「解像度」を高めるための要素。`}</p>
    <p>{`CLINICS においては、医療情報を扱う上での規制（3 省 2 ガイドライン）や、医療機関（医師・医事・診療科の特性）の業務、診療報酬についての知識、法改正、レセコン（ORCA）... と様々あります。`}</p>
    <h2>{`技術知識`}</h2>
    <p>{`目的を達成する為に必要な「指し手」を選択するための要素。`}</p>
    <p>{`（エンジニアにとっては説明するまでもない内容であると思いますが）
ここが欠けてしまうと絵に描いた餅で終わってしまいます。メドレーのエンジニアにおいても日々研鑽し、プロダクトに対してコミットを続けています。`}</p>
    <h2>{`行動力`}</h2>
    <p>{`目的を達成するための「推進力」を高めるための要素。`}</p>
    <p>{`各種知識と整理した情報を推進力に変えていく為には、その時の状況に応じた動きをする必要があります。ステークホルダーとの調整は当然ですが、組織内連携といった「横の動き」も必要です。`}</p>
    <h2>{`想像力`}</h2>
    <p>{`これまで挙げたそれぞれの手段を適切に利用していくための要素としての土台が「想像力」であると考えています。`}</p>
    <p><strong parentName="p">{`課題（Issue）への取り組み例`}</strong></p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201023/20201023135418.png",
      "alt": "20201023135418.png"
    }}></img>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`番号`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`概要`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`①`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue に取り組む際に「本当に解決すべきこと」についての想像を働かせます。Issue に記載されていることが `}<strong parentName="td">{`本当にプロダクトとして解決すべきことなのか`}</strong>{` を含めて考えます。これまでの運用が必ずしも正しいわけではない。という点がポイントです。`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`②`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`① について「想像のまま」で終わらせてはいけないので、業務知識と照らし合わせて確度を高めていきます。常勤医師やカスタマーサポートにヒアリングしながら、`}<strong parentName="td">{`医療業務としてのあるべき形の解像度を上げていく`}</strong>{` プロセスです。`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`③`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`① 及び ② で高めた解像度は言葉の延長線上なので、関係者間の認識のギャップが発生しやすいです。プロトタイプを作成して、視覚・触感レベルでギャップを埋めていくことで、あるべき形に向けて洗練させていきます。`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`④`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`① 〜 ③ のタイミングを問わず、必要に応じて関係者と相談しながら進めていきます。エンジニアが立てた仮説をデザイナーの目線で評価・ UI/UX 最適化をして頂いたり、大きめの機能については、医療機関にパイロット運用のご協力を仰いだりすることもあります。`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`① 及び ② の項に作業のウェイトが偏っているように見えるかと思います。実際、課題を解決する為の半分以上をここに割いています。`}</p>
    <p>{`理由は「１度作って公開した機能」は、その後１人歩きをして作成者の意図しない方向で利用をされることがあるからです。`}</p>
    <p>{`そして、利用者がその運用を定着させてしまうと `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`誤った機能においても「削ぎ落とすことが困難」`}</code>{` です。これは「使われていない機能」よりも直接的な負債といった形でボディブローのように効いてきます。`}</p>
    <ul>
      <li parentName="ul">{`「どのような使われ方をするか」について想像すること`}</li>
      <li parentName="ul">{`その使われ方が、プロダクトの目指す世界と合っていること`}</li>
    </ul>
    <p><strong parentName="p">{`エンジニアは技術を形にする上で、常に想像力を働かせて取り組む必要がある。`}</strong>{` というのが筆者の持論です。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`執筆の締めにあたって CTO ブログを見返してみると、大事なことはここに詰まっていました。筆者は前職の SIer 時代に読んだのですが、この記事にすごく共感したのを覚えています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://toppa.medley.jp/02.html"
      }}>{`https://toppa.medley.jp/02.html`}</a></p>
    <p>{`メドレーでは `}<a parentName="p" {...{
        "href": "https://www.medley.jp/"
      }}>{`医療ヘルスケアの未来を作る`}</a>{` という大きな目標、そしてその未来を作る為に解決すべき課題に向かって、今回ご紹介したプロセスや考え方も含め試行錯誤しながら、事業部一丸でプロダクト開発を推進しています。`}</p>
    <p>{`エンジニアの総合力を発揮して医療ヘルスケアの未来を一緒に作り上げていきたい！という方にお会い出来ることを楽しみにしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      